import React, { useState } from "react";
import axios from "axios";
import config from "../config";

function App() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const response = await axios.post(`${config.urls.appsumo}`, formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.data?.success === false) {
                setError(response.data?.error || "Something went wrong!");
            } else {
                setSuccess("Registration successful!");
            }

        } catch (err) {
            setError(err.response?.data?.message || "Something went wrong!");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="body">
            <div className="form-container">
                <img src="/RessoAI-logo.svg" alt="Resso.ai Logo" className="logo" />
                <h1>Welcome Sumo-ling!</h1>
                <h2>APPSUMO</h2>
                <p>
                    Join over 100K+ job seekers applying with ATS tailored resumes to
                    increase interview chances by <span className="highlight">10x</span>.
                </p>
                <form onSubmit={handleSubmit}>
                    <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
                    <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
                    <input type="email" name="email" placeholder="Enter email" value={formData.email} onChange={handleChange} required />
                    <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
                    <button className='button' type="submit" disabled={loading}>{loading ? "Signing up..." : "Sign up"}</button>
                </form>
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}
                <p>Already have an account? <a href="">Login</a></p>
            </div>
        </div>
    );
}

export default App;
