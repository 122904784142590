import React from "react";
import Generator from "./pages/generator";
import History from "./pages/history";
import PracticeList from "./pages/interview-practice";
import PreparednessGenerator from "./pages/preparedness-generator";
import Interview from "./pages/interview";
import QuestionList from './pages/interview-questions';
import AppsumoLogin from "./pages/appsumoLogin";

const routes = [
   { path: "generator", element: <Generator /> },
   { path: "history", element: <History /> },
   { path: "interview", element: <Interview /> },
   { path: "interview-practice", element: <PracticeList /> },
   { path: 'interview-questions', element: <QuestionList />},
   { path: "preparedness-generator", element: <PreparednessGenerator /> },
   { path: "appsumo-login", element: <AppsumoLogin /> }
];

export default routes;
