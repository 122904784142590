import React, { useState } from 'react';
import axios from 'axios';
import useUserParam from '../hooks/useUserParam';
import config from '../config';
import { Alert, Badge, Button, Form, Spinner } from "react-bootstrap";

function Generator() {
  // Use custom hook to fetch user parameter
  const user = useUserParam();

  // Array that holds the text content for the button, depending on the working state
  const buttonText = [
    "Create Statement of Purpose",
    "Generating Statement of Purpose",
  ];

  // State hooks to manage form data, SOP text, and loading state
  const [formData, setFormData] = useState({
    school: "",
    program: "",
    programInfo: "",
    programURL: "",
  });
  const [sop, setSop] = useState("");
  const [working, setWorking] = useState(false);
  const [alert, setAlert] = useState("");

  // Event handler to update form state when input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to copy the generated SOP to the clipboard
  async function copyToClipboard() {
    if (sop !== "") {
      try {
        await navigator.clipboard.writeText(sop);
        console.log("Text copied to clipboard: ", sop);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    }
  }

  // Event handler for form submission to generate Statement of Purpose
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setWorking(true); // Set working state to true to show loading

    let missingFields = [];

    // School and Program must always be filled
    if (!formData.school) {
      missingFields.push("School Name");
    }
    if (!formData.program) {
      missingFields.push("Program Name");
    }

    // Either programInfo or programURL must be filled
    if (!formData.programInfo && !formData.programURL) {
      missingFields.push(
        "Program Description or Program URL (at least one required)"
      );
    }

    if (missingFields.length > 0) {
      setAlert(
        `Please fill in the following required fields: ${missingFields.join(
          ", "
        )}.`
      );
      setWorking(false);
      return;
    } else {
      setAlert(false);
      try {
        // Make POST request to backend to generate SOP
        const response = await axios.post(config.urls.generator, {
          user: user,
          school: formData.school,
          program: formData.program,
          info: formData.programInfo,
          url: formData.programURL,
        });
        // Set the generated SOP text to state after receiving the response
        setSop(response.data.result);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
      setWorking(false); // Set working state to false after the process is done
    }
  };

  return (
    <div className="App">
      {alert && <Alert variant="danger">{alert}</Alert>}
      {/* Form to input school, program, and program URL */}
      <Form onSubmit={handleFormSubmit}>
        <Form.Group>
          <Form.Label className="fw-bold">School Name</Form.Label>
          <Form.Control
            type="text"
            name="school"
            placeholder="Enter school name"
            value={formData.school}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label className="fw-bold">Program Name</Form.Label>
          <Form.Control
            type="text"
            name="program"
            placeholder="Enter program name"
            value={formData.program}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label className="fw-bold">Program URL</Form.Label>
          <Form.Control
            type="text"
            name="programURL"
            placeholder="Enter program URL"
            value={formData.programURL}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label className="fw-bold">Program Description</Form.Label>
          <Form.Control
            as="textarea"
            name="programInfo"
            placeholder="Enter program description"
            rows={5}
            value={formData.programInfo}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="d-flex justify-content-center my-4">
          <Button variant={working ? "primary" : "success"} type="submit">
            {working ? (
              <>
                <Spinner animation="border" size="sm" />
                {"  "}
                {buttonText[1]} {/* "Generating..." text */}
              </>
            ) : (
              buttonText[0]
            )}
          </Button>
        </Form.Group>
      </Form>
      {/* Form to display and copy the generated Statement of Purpose */}
      <Form>
        <Form.Group className="mt-3">
          <div className="d-flex justify-content-between mb-2">
            <Form.Label className="fw-bold">
              Generated Statement of Purpose
            </Form.Label>
            <Badge
              bg={sop === "" ? "dark" : "warning"}
              className={`d-flex align-items-center py-0 ${
                sop === "" ? "pe-none" : "cursor-pointer"
              }`}
              onClick={copyToClipboard}
            >
              Copy to clipboard
            </Badge>
          </div>
          <div
            className="form-control bg-light"
            style={{
              height: "200px",
              overflowY: "auto",
              padding: "1rem",
              whiteSpace: "pre-wrap",
            }}
          >
            {sop ? sop : "Your statement of purpose will appear here."}
          </div>
        </Form.Group>
      </Form>
    </div>
  );
}

export default Generator;
